<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.check', { name: $tc('message.calendar', 1)}) }}</h3>
    <transition name="fader">
    <ol class="flix-list-group" :key="updateKey">
      <li class="flix-list-group-item" v-for="(check, title) in list" :key="title">
        {{ title }}&nbsp;
        <flixIcon :id="'warning-sign'" v-if="!check[0]" class="flix-text-danger"/>
        <flixIcon :id="'ok'" class="flix-text-success" v-else/>&nbsp;
        <small v-if="!check[0]">( {{ $t('message.edit', { name: check[1] }) }} )</small>
      </li>
    </ol>
  </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: { data: Object, callback: Function },
  data () {
    var checks = {}
    checks[this.$t('message.calendarFormat')] = [this.checkTitle(), this.$t('message.calendarFormat')]
    checks[this.$t('message.timeRange')] = [this.checkDates(), this.$t('message.timeRange')]
    checks[this.$tc('message.appointmentRequests', 2)] = [this.checkMaxApplicants(), this.$tc('message.appointmentRequests', 2)]
    checks[this.$tc('message.form', 1)] = [this.checkForm(), this.$tc('message.form', 1)]

    return {
      list: {},
      checks: checks,
      updateKey: new Date().getTime()
    }
  },
  methods: {
    checkTitle () {
      if (this.data.title.length > 2) {
        return true
      }
      this.error = true
      return false
    },
    checkDates () {
      if (this.data.type === 'datepicker') {
        if (Object.keys(this.data.include.weekdays).length) {
          return true
        }
      }

      if (this.data.type === 'eventpicker') {
        if (
          typeof this.data.events === 'object' &&
          typeof this.data.events[0] === 'object' &&
          typeof this.data.events[0].begin === 'string' &&
          typeof this.data.events[0].end === 'string'
        ) {
          return true
        }
      }
      this.error = true
      return false
    },
    checkForm () {
      if (this.data.form.length) {
        return true
      }
      this.error = true
      return false
    },
    checkMaxApplicants () {
      if (this.data.type === 'datepicker') {
        if (Object.keys(this.data.max_applicants).length === Object.keys(this.data.include.weekdays).length) {
          return true
        }
      }
      if (this.data.type === 'eventpicker') {
        if (Object.keys(this.data.max_applicants).length === Object.keys(this.data.events).length) {
          return true
        }
      }
      this.error = true
      return false
    },
    setCheckAnimation () {
      if (Object.keys(this.checks).length === Object.keys(this.list).length) {
        if (!this.error) {
          setTimeout(function () {
            this.callback()
          }.bind(this), 1000)
        }
        return false
      }
      setTimeout(function () {
        var key = Object.keys(this.checks)[Object.keys(this.list).length]
        var value = Object.values(this.checks)[Object.keys(this.list).length]
        this.list[key] = value
        this.updateKey = new Date().getTime()
        this.setCheck()
      }.bind(this), 1000)
    },
    setCheck () {
      if (!this.error) {
        this.callback()
      }
      this.list = this.checks
    }
  },
  mounted () {
    this.setCheck()
  },
  computed: {

  }
}
</script>
